
import Money from '../../helpers/Money';

export default {
  components: {Money},
  props: {
    position: {type: Object, required: true},
    config: {type: Object, required: true},
    limit: {type: Object, required: true},
    departureDate: {type: Object},
    returnDate: {type: Object},
    selection: {type: String},
    open: {type: Boolean, required: true},
    prices: {type: Object},
    classes: {type: String, default: ''}
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      months: {},
      today: this.$moment(),
      selected: {},
      hover: {},
      trip: {},
      selectedSameDay: false
    };
  },
  methods: {
    getPrice(day) {
      if (typeof this.prices !== 'undefined' && Object.keys(this.prices).length > 0) {
        if (this.$store.state.searchFlight.search_type === 'roundtrip' && this.selection === 'return') {
          // Return price
          if (this.prices[this.departure_date.format('YYYY-MM-DD')] &&
              this.prices[this.departure_date.format('YYYY-MM-DD')].prices &&
              this.prices[this.departure_date.format('YYYY-MM-DD')].return[day.format('YYYY-MM-DD')] &&
              this.prices[this.departure_date.format('YYYY-MM-DD')].return[day.format('YYYY-MM-DD')]
          ) {
            if (this.prices[this.departure_date.format('YYYY-MM-DD')].return[day.format('YYYY-MM-DD')].sale_price_adult) {
              if (this.$i18n.localeProperties.flight.search === 'commission') {
                return this.prices[this.departure_date.format('YYYY-MM-DD')]
                    .return[day.format('YYYY-MM-DD')].sale_price_adult_commission;
              } else {
                return this.prices[this.departure_date.format('YYYY-MM-DD')]
                    .return[day.format('YYYY-MM-DD')].sale_price_adult;
              }
            } else if (this.prices[this.departure_date.format('YYYY-MM-DD')].return[day.format('YYYY-MM-DD')].price_adult) {
              return this.prices[this.departure_date.format('YYYY-MM-DD')].return[day.format('YYYY-MM-DD')].price_adult;
            }
          } else if (this.prices.return &&
              this.prices.return[day.format('YYYY-MM-DD')]) {
            if (this.prices.return[day.format('YYYY-MM-DD')].sale_price_adult) {
              return this.prices.return[day.format('YYYY-MM-DD')].sale_price_adult;
            }
            else if (this.prices.return[day.format('YYYY-MM-DD')].price_adult) {
              return this.prices.return[day.format('YYYY-MM-DD')].price_adult;
            }
          }
        } else {
          // Departure price
          if (this.prices.outward[day.format('YYYY-MM-DD')]) {
            if (this.prices.outward[day.format('YYYY-MM-DD')].sale_price_adult) {
              if (this.$i18n.localeProperties.flight.search === 'commission') {
                return this.prices.outward[day.format('YYYY-MM-DD')].sale_price_adult_commission;
              } else {
                return this.prices.outward[day.format('YYYY-MM-DD')].sale_price_adult;
              }
            } else if ( this.prices.outward[day.format('YYYY-MM-DD')].price_adult) {
                return this.prices.outward[day.format('YYYY-MM-DD')].price_adult;
            }
          }
        }
      }
      return '●●●';
    },
    setPosition() {
      if (this.config.allMonths) {
        this.startDate = this.$moment()
            .startOf('month');
        this.endDate = this.$moment()
            .startOf('month')
            .add(1, 'year');
      } else {
        if (this.$store.state.searchFlight.search_type === 'roundtrip' && this.selection === 'return') {
          if (this.startDate.unix() < this.$moment(this.departure_date)
              .startOf('month').unix()) {

            this.startDate = this.$moment(this.departure_date)
                .startOf('month');
            this.endDate = this.$moment(this.departure_date)
                .add(this.config.months - 1, 'months')
                .endOf('month');
          } else {
            this.startDate = this.position.clone()
                .startOf('month');
            this.endDate = this.position.clone()
                .add(this.config.months - 1, 'months')
                .endOf('month');
          }
        } else {
          this.startDate = this.position.clone()
              .startOf('month');
          this.endDate = this.position.clone()
              .add(this.config.months - 1, 'months')
              .endOf('month');
        }
      }
      this.setDays();
    },
    setDays() {
      let startDate = this.startDate.clone();
      let endDate = this.endDate.clone();
      let currentDate = this.startDate.clone();

      this.months = {};
      let week = 0;

      while (currentDate < endDate) {
        let keyMonth = currentDate.format('YYYYMM');
        if (typeof this.months[keyMonth] === 'undefined') {
          this.months[keyMonth] = {days: {}, month: currentDate.clone()};
        }
        let keyDay = (parseInt(currentDate.format('E')) + parseInt(this.$i18n.localeProperties.startWeek)) % 7;

        if (keyDay % 7 === 0) {
          week++;
        }

        if (typeof this.months[keyMonth].days[week] === 'undefined') {
          this.months[keyMonth].days[week] = {};
        }

        this.months[keyMonth].days[week][keyDay] = {
          day: currentDate.clone()
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
              })
        };
        currentDate.add(1, 'day');
      }

      // Fill gaps
      Object.keys(this.months)
          .forEach((month_key) => {
            Object.keys(this.months[month_key].days)
                .forEach((days_key) => {
                  if (Object.keys(this.months[month_key].days[days_key]).length < 7) {
                    let first_day_key = Object.keys(this.months[month_key].days[days_key])[0];

                    for (let i = 0; i < 7; i++) {
                      if (typeof this.months[month_key].days[days_key][i] === 'undefined') {
                        let day_week = i - (parseInt(
                            this.$i18n.localeProperties.startWeek) % 7);
                        this.months[month_key].days[days_key][i] = {};

                        if (this.months[month_key].days[days_key][first_day_key].day.format(
                            'D') === '1') {
                          this.months[month_key].days[days_key][i].day = this.months[month_key].days[days_key][first_day_key].day.clone()
                              .subtract(
                                  first_day_key - i,
                                  'days');
                        } else {
                          this.months[month_key].days[days_key][i].day = this.months[month_key].days[days_key][first_day_key].day.clone()
                              .add(
                                  i,
                                  'days');
                        }
                        this.months[month_key].days[days_key][i].other_month = true;
                      }
                    }

                  }
                });
          });

      // Mark out of limits days
      Object.keys(this.months)
          .forEach((month_key) => {
            Object.keys(this.months[month_key].days)
                .forEach((days_key) => {
                  Object.keys(this.months[month_key].days[days_key])
                      .forEach((day) => {
                        if (this.months[month_key].days[days_key][day].day.clone()
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                              millisecond: 999
                            }) < this.minDate) {
                          this.months[month_key].days[days_key][day].deactivated = true;
                        }
                        if (this.months[month_key].days[days_key][day].day > this.maxDate) {
                          this.months[month_key].days[days_key][day].deactivated = true;
                        }
                      });
                });
          });
    },

    setBackgrounds(selected_day) {
      this.selected = {};
      this.hover = {};
      this.trip = {};

      if (typeof this.departure_date !== 'undefined' && this.departure_date !== null) {
        this.selected[this.departure_date.format('YYYYMMDD')] = true;
      }

      if (typeof selected_day !== 'undefined') {
        if (selected_day.deactivated) return;
        this.hover[selected_day.day.format('YYYYMMDD')] = true;
      }

      if (this.search_type === 'roundtrip') {
        if (typeof this.return_date !== 'undefined' && this.return_date !== null) {
          this.selected[this.return_date.format('YYYYMMDD')] = true;
        }

        if (typeof this.departure_date !== 'undefined' && this.departure_date !== null && typeof this.return_date !== 'undefined' && this.return_date !== null) {
          this.selectedSameDay = true;
        } else {
          this.selectedSameDay = false;
        }

        this.trip = {};
        Object.keys(this.months)
            .forEach((month_key) => {
              Object.keys(this.months[month_key].days)
                  .forEach((days_key) => {
                    Object.keys(this.months[month_key].days[days_key])
                        .forEach((day) => {
                          if (this.selection === 'return' && typeof selected_day !== 'undefined') {
                            if (this.months[month_key].days[days_key][day].day > this.departure_date && this.months[month_key].days[days_key][day].day < selected_day.day) {
                              this.trip[this.months[month_key].days[days_key][day].day.format(
                                  'YYYYMMDD')] = true;
                            }
                          }
                          if (this.departure_date !== null && this.return_date !== null) {
                            if (this.months[month_key].days[days_key][day].day > this.departure_date && this.months[month_key].days[days_key][day].day < this.return_date) {
                              this.trip[this.months[month_key].days[days_key][day].day.format(
                                  'YYYYMMDD')] = true;
                            }
                          }
                        });
                  });
            });
      }

    },

    setDate(selected_day) {
      if (selected_day.deactivated) return;
      if (this.selection === 'departure') {
        if (selected_day.day > this.return_date) {
          this.departure_date = selected_day.day;
          this.return_date = selected_day.day;
        }
        this.departure_date = selected_day.day;
        if (this.$store.state.searchFlight.search_type === 'oneway') {
          this.$emit('update:selection', '');
          this.$emit('update:open', false);
          this.$emit('close', false);
          this.$emit('selected-date');
        } else {
          this.$emit('update:selection', 'return');
          if (typeof this.return_date !== 'undefined' && this.return_date !== null) {
            if (selected_day.day > this.return_date) {
              this.$emit('update:returnDate', null);
            }
          }
          this.$emit('selected-date');
        }
      } else {
        if (this.selection === 'return') {
          if (selected_day.day < this.departure_date) {
            this.departure_date = selected_day.day;
            this.return_date = selected_day.day;
          } else {
            this.$emit('update:selection', '');
            this.return_date = selected_day.day;
            this.$emit('update:open', false);
            this.$emit('close', false);
            this.$emit('selected-date');
          }
        }
      }
      this.$nextTick(() => {
        this.setBackgrounds(selected_day);
      });
    },

    positionUser() {
      if (process.client) {
        if (this.selection === 'departure') {
          if (window.document.querySelector('.mobile-date .month-' + this.departure_date.format('MMYYYY'))) {
            setTimeout(() => {
              window.document.querySelector('.mobile-date .month-' + this.departure_date.format('MMYYYY'))
                  .scrollIntoView();
            }, 100);
          }
        } else {
          if (window.document.querySelector('.mobile-date .month-' + this.return_date.format('MMYYYY'))) {
            setTimeout(() => {
              window.document.querySelector('.mobile-date .month-' + this.return_date.format('MMYYYY'))
                  .scrollIntoView();
            }, 100);
          }
        }
      }
    },

    prevMonth() {
      this.$emit('update:position', this.position.clone()
          .subtract(1, 'month'));
    },
    nextMonth() {
      this.$emit('update:position', this.position.clone()
          .add(1, 'month'));
    }
  },
  computed: {
    departure_date: {
      // getter
      get: function () {
        return this.$moment(this.$store.state.searchFlight.departure_date);
        // .tz(this.$store.state.searchFlight.departure && this.$store.state.searchFlight.departure.timezone ? this.$store.state.searchFlight.departure.timezone : 'Europe/Bucharest');
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setDepartureDate', newValue.format('YYYY-MM-DD'));
      }
    },
    return_date: {
      // getter
      get: function () {
        return this.$moment(this.$store.state.searchFlight.return_date);
        // .tz(this.$store.state.searchFlight.departure && this.$store.state.searchFlight.departure.timezone ? this.$store.state.searchFlight.departure.timezone : 'Europe/Bucharest');
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setReturnDate', newValue.format('YYYY-MM-DD'));
      }
    },

    search_type: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.search_type;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setSearchType', newValue);
      }
    },

    departure: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.departure;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setDeparture', newValue);
      }
    },
    destination: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.destination;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setDestination', newValue);
      }
    },
    prevMonthEnable() {
      return this.limit.min > this.startDate;
    },
    nextMonthEnable() {
      return this.limit.max < this.endDate;
    },
    minDate() {
      if (this.selection === 'return' && this.departure_date) {
        return this.departure_date.clone()
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0
            });
      } else {
        return this.limit.min; /*.set({
                    hour       : 0,
                    minute     : 0,
                    second     : 0,
                    millisecond: 0
                }); */
      }
    },
    maxDate() {
      return this.limit.max.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999
      });
    }
  },
  created() {
    this.setPosition();
    setInterval(() => {
      this.$moment();
    }, 60000);
  },
  watch: {
    position: {
      handler() {
        this.setPosition();
        this.setBackgrounds();
      }
    },
    selection: {
      immediate: true,
      handler(value) {
        if (value === 'departure') {
          if (this.$moment(this.departure_date).isValid()) {
            this.position = this.$moment(this.departure_date);
          }
        } else {
          if (this.$moment(this.return_date).isValid()) {
            this.position = this.$moment(this.return_date);
          }
        }
      }
    },
    open: {
      handler(value) {
        if (this.selection === 'departure') {
          if (this.departure_date === null || !this.departure_date.isValid()) {
            this.$emit('update:position', this.$moment()
                .startOf('month'));
          } else {
            this.$emit('update:position', this.departure_date.clone()
                .startOf('month'));
          }
        } else if (this.selection === 'return') {
          if (this.return_date === null || !this.return_date.isValid()) {
            if (this.departure_date === null || !this.departure_date.isValid()) {
              this.$emit('update:position', this.$moment()
                  .startOf('month'));
            } else {
              this.$emit('update:position', this.departure_date.clone()
                  .startOf('month'));
            }
          } else {
            this.$emit('update:position', this.return_date.clone()
                .startOf('month'));
          }
        }

        this.setPosition();
        this.setBackgrounds();
        if (value) {
          this.positionUser();
        }
      }
    },
    departureDate: {
      immediate: true,
      handler() {
        this.setBackgrounds();
      }
    },
    limit: {
      immediate: true,
      deep: true,
      handler() {
        if (this.departure_date < this.limit.min) {
          this.departure_date = this.limit.min;
        }
        if (this.return_date < this.limit.min) {
          this.return_date = this.limit.min;
        }
      }
    },
    returnDate: {
      immediate: true,
      handler() {
        this.setBackgrounds();
      }
    }
  }
};
