
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      localOpen: false,
      internalInterval: null,
      vcoConfig: {
        handler: this.hide,
        events: ['dblclick', 'click'],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behavior is behind a flag.
        detectIFrame: true,
        // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
        //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
        capture: true
      }
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    mobileFull: {
      type: Boolean,
      default: true
    },
    closeOnBack: {type: Boolean, default: false}
  },
  methods: {
    show() {
      this.localOpen = true;
      this.$emit('update:open', this.localOpen);
    },
    toggle() {
      this.localOpen = !this.localOpen;
      console.log("Toggle trig ", this.localOpen);
      this.$emit('update:open', this.localOpen);
    },
    hide() {
      if (this.localOpen) {
        console.log("Call hide");
        this.localOpen = false;
        this.$emit('update:open', this.localOpen);
      }
    }
  },
  created() {
    if (process.client && this.closeOnBack && this.$responsive.isMobile()) {
      if (window.location.hash === '#open' || window.location.hash === 'open') {
        window.history.replaceState({}, '', this.localePath({
          name: this.$route.name.split('___')[0],
          params: this.$route.params,
          query: this.$route.query,
          hash: ''
        }));
      }
    }
  },
  watch: {
    open(newVal, oldVal) {
      this.localOpen = newVal;
      if (newVal === true) {
        if (this.$responsive.isMobile() && this.closeOnBack) {
          window.history.pushState({}, '', this.localePath({
            name: this.$route.name.split('___')[0],
            params: this.$route.params,
            query: this.$route.query,
            hash: '#open'
          }));
          window.document.body.style.overflow = 'hidden';
        }
      } else {
        window.document.body.style.overflow = 'auto';
        if (this.$responsive.isMobile() && this.closeOnBack) {
          if (window.location.hash === '#open' || window.location.hash === 'open') {
            window.history.pushState({}, null, this.localePath({
              name: this.$route.name.split('___')[0],
              params: this.$route.params,
              query: this.$route.query,
              hash: ''
            }));
          }
        }
      }
    }
  },

  mounted() {
    if (process.client) {
      window.document.body.style.overflow = 'auto';
      if (this.$responsive.isMobile() && this.closeOnBack) {
        this.internalInterval = setInterval(() => {
          this.$nextTick(() => {
            if (this.open && window.location.hash === '') {
              this.$emit('update:open', false);
            }
          });
        }, 100);
      }
    }
  },
  unmounted() {
    if (process.client) {
      clearInterval(this.internalInterval);
      window.document.body.style.overflow = 'auto';
    }
  },
};
