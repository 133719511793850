// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/icon/close-white.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".current-selection[data-v-151930c6]{background-color:#1558a9;border-radius:4px;color:#fff;font-weight:500;height:30px;margin:5px 0;padding:5px 5px 5px 10px;text-transform:uppercase}.current-selection .text-container[data-v-151930c6]{display:inline-block;width:calc(100% - 20px)}.current-selection .close[data-v-151930c6]{background-color:#5c8ac3;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:10px;border-radius:5px;cursor:pointer;display:inline-block;height:16px;position:relative;top:-2px;width:16px}.date-travel-container[data-v-151930c6],.input-container[data-v-151930c6]{position:relative}.input-container[data-v-151930c6]{background-color:#fff;border:1px solid #ddd;border-radius:3px;margin:1px;overflow:hidden;padding:0 12px;z-index:10}.input-container.focus[data-v-151930c6]{border:2px solid #1558a9;margin:0}.input-container input[data-v-151930c6]{color:#1558a9;font-weight:500;height:41px}.input-container label[data-v-151930c6]{display:inline-block;padding:10px 0}.container-date.selected-input[data-v-151930c6]:after{border-bottom:10px solid #777;border-left:10px solid transparent;border-right:10px solid transparent;content:\" \";display:block;height:0;margin-left:30px;position:absolute;width:0}.container-date.selected-input .input-container[data-v-151930c6]{border:2px solid #1558a9;margin:0}.container-date.selected-input .input-container input[data-v-151930c6]{width:80px}.mobile-date .container-date.selected-input[data-v-151930c6]:after{content:\"\";display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
