
import ClickOutside from 'vue-click-outside';
import DateTravelCalendar from './DateTravel/Calendar';
import Dropdown from './Dropdown';

export default {
  components: {Dropdown, DateTravelCalendar},
  directives: {
    ClickOutside
  },
  props: {
    usage: {type: String, default: null}
  },
  data() {
    return {
      name: '',
      config: {
        months: this.$responsive.isTablet() ? 1 : 2,
        allMonths: false,
        scrollTo: false,
        navigation: true
      },
      configMobile: {
        months: 1,
        allMonths: false,
        scrollTo: false,
        navigation: true
      },
      position: null,

      // Min date
      limit: {
        min: null, max: null
      },
      selection: '',
      open: false,
      openMobile: false,
      prices: {}
    };
  },
  methods: {
    closeDialog() {
      this.openMobile = false;
    },
    init() {
      this.updateDateMinMax();
      this.position = this.$moment()
          .tz(this.departure && this.departure.timezone ? this.departure.timezone : 'Europe/Bucharest')
          .startOf('month');
      setInterval(this.updateDateMinMax, 60000);
      this.name = Array(15)
          .fill(null)
          .map(() => Math.random()
              .toString(20)
              .substr(2))
          .join('');

      this.loadPrices();

      this.$globalEvent.$off('updateCalendar');
      this.$globalEvent.$on('updateCalendar', () => {
        this.loadPrices();
      });
    },

    loadPrices() {
      if (
          this.departure &&
          this.departure.airport_code &&
          this.destination &&
          this.destination.airport_code
      ) {
        this.$api.flightCalendar.simple(this.$store.state.searchFlight.departure.airport_code,
            this.$store.state.searchFlight.destination.airport_code, this.$store.state.searchFlight.search_type)
            .then((r) => {
              this.prices = r.data.calendar.calendar;
            });
      }
    },

    updateDateMinMax() {
      this.limit.min = this.$moment.tz(
          this.$moment()
              .tz(this.departure && this.departure.timezone ? this.departure.timezone : 'Europe/Bucharest')
              .add(8, 'hours')
              .format('YYYY-MM-DD HH:mm:ss')
          , 'Europe/Bucharest'
      );

      this.limit.max = this.$moment()
          .tz(this.departure && this.departure.timezone ? this.departure.timezone : 'Europe/Bucharest')
          .add(1, 'year');
    },
    show(type) {
      console.log('called show ', type);
      this.selection = type;
      this.open = true;

      if (type === 'return') {
        if (this.return_date !== null) {
          this.position = this.$moment(this.return_date).startOf('month');
        }
      }
    },
    showMobile(type) {
      this.selection = type;
      this.openMobile = true;

      if (type === 'return') {
        if (this.return_date !== null) {
          this.position = this.$moment(this.return_date).startOf('month');
        }
      }
    },
    hide() {
      this.open = false;
    }
  },
  computed: {
    departure_date: {
      // getter
      get: function () {
        if (this.$store.state.searchFlight.departure_date === null) {
          return null;
        } else {
          return this.$moment(this.$store.state.searchFlight.departure_date);
        }
        // .tz(this.departure && this.departure.timezone ? this.departure.timezone : 'Europe/Bucharest');
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setDepartureDate', newValue.format('YYYY-MM-DD'));
      }
    },
    return_date: {
      // getter
      get: function () {
        if (this.$store.state.searchFlight.return_date === null) {
          return null;
        } else {
          return this.$moment(this.$store.state.searchFlight.return_date);
        }
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setReturnDate', newValue.format('YYYY-MM-DD'));
      }
    },

    search_type: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.search_type;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setSearchType', newValue);
      }
    },

    departure: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.departure;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setDeparture', newValue);
      }
    },
    destination: {
      // getter
      get: function () {
        return this.$store.state.searchFlight.destination;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('searchFlight/setDestination', newValue);
      }
    }
  },
  created() {
    this.init();
  },
  watch: {
    departure: {
      handler() {
        this.init();
      }
    },
    destination: {
      handler() {
        this.init();
      }
    },
    // departureDateLocal: {
    //     immediate: true,
    //     handler (value) {
    //         if (typeof value !== 'undefined' && value !== null) {
    //             this.$emit('update:departureDate', value);
    //         }
    //     }
    // },
    // returnDateLocal   : {
    //     immediate: true,
    //     handler (value) {
    //         if (typeof value !== 'undefined' && value !== null) {
    //             this.$emit('update:returnDate', value);
    //         }
    //     }
    // },
    departureDate: {
      immediate: true,
      handler(value) {
        this.departureDateLocal = this.$moment(value);
      }
    },
    returnDate: {
      immediate: true,
      handler(value) {
        this.returnDateLocal = this.$moment(value);
      }
    }
  }
};
